<template>
	<div id="champion-index-div">
		<div class="champion-content-summary-div">
			<h3>
				Patch: <span class="highlightText">{{ patch }}</span>
			</h3>
			<p>
				There are
				<span class="highlightText">{{ totalGames | thousandsFilter }}</span>
				matches from Platinum++ players populated for the current patch that
				match this query.
			</p>
		</div>

		<div>
			<div class="champStatSummary">
				<div class="champLoadingImgDiv">
					<img :src="`/images/championLoading/${getChampionData.championImgName}_0.jpg`" />
				</div>
				<div>
					<p>vs.</p>
				</div>
				<div class="champLoadingImgDiv">
					<img :src="`/images/championLoading/${getOpponentData.championImgName}_0.jpg`" />
				</div>
			</div>

			<div id="vsStatSummary">
				<table>
					<tbody>
						<tr>
							<td>{{ data.champion.wins }}</td>
							<td>Wins</td>
							<td>{{ data.opponent.wins }}</td>
						</tr>
						<tr>
							<td>{{ data.champion.fbKills }}</td>
							<td>FB Kills</td>
							<td>{{ data.opponent.fbKills }}</td>
						</tr>
						<tr>
							<td>{{ data.champion.fbDeaths }}</td>
							<td>FB Deaths</td>
							<td>{{ data.opponent.fbDeaths }}</td>
						</tr>
						<tr>
							<td>{{ data.champion.ftKills }}</td>
							<td>FT Kills</td>
							<td>{{ data.opponent.ftKills }}</td>
						</tr>
						<tr>
							<td>{{ data.champion.kda }}</td>
							<td>KDA</td>
							<td>{{ data.opponent.kda }}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
	name: 'page-champ-vs',
	props: {
		champDataGrab: Object
	},
	data () {
		return {
			patch: '',
			totalGames: '',
			championVersion: ''
		};
	},
	computed: {
		...mapState(['champDataGrab', 'championVersion', 'view', 'totalGames', 'patch', 'data']),
		...mapGetters(['getChampionList', 'getChampionData', 'getOpponentData', 'getBestAgainstSummary', 'getWorstAgainstSummary', 'getMostPlayedAgainstSummary'])
	},
	filters: {
		thousandsFilter (num) {
			let numParts = num.toString();
			numParts = numParts.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
			return numParts;
		}
	},
	mounted () {
		this.patch = this.champDataGrab.patch;
		this.totalGames = this.champDataGrab.data.champion.picks;
	}
};
</script>

<style lang="scss" scoped>
.champStatSummary {
	display: grid;
	grid-template-columns: repeat(3, auto);
	width: max-content;
	margin: 25px auto;
	background: var(--nav-bg-color);
	border: 1px solid var(--champ-nav-item-border);
}

.champLoadingImgDiv {
	margin: 0 10px 0 0;

	img {
		height: 200px;
		width: 110px;
		float: left;
	}
}

.champStatsTableDiv {
	margin: 10px 10px;
}

.champStatsLeftTable tbody tr td:nth-child(1) {
	text-align: right;
}

.champStatsLeftTable tbody tr td:nth-last-child(1) {
	text-align: left;
}
</style>
