var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"champStatDiv"},[_c('h3',[_vm._v(_vm._s(_vm.title))]),_c('div',{staticClass:"champStatDivList"},[_vm._l((_vm.data),function(champion,index){return _c('p',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
				content: _vm.title === 'Win Rate'
					? ((champion.wins) + " W - " + (champion.losses) + " L")
					: _vm.title === 'KDA'
						? (((champion.kills / champion.picks).toFixed(2)) + " / " + ((champion.deaths / champion.picks).toFixed(2)) + " / " + ((champion.assists / champion.picks).toFixed(2)))
						: _vm.title === 'Pick Rate'
							? ((champion.picks) + " / " + (_vm.$store.state.totalGames - champion.bans))
							: _vm.title === 'Ban Rate'
								? ((champion.bans) + " / " + (_vm.$store.state.totalGames))
								: null,
				trigger: 'hover'
			}),expression:"{\n\t\t\t\tcontent: title === 'Win Rate'\n\t\t\t\t\t? `${champion.wins} W - ${champion.losses} L`\n\t\t\t\t\t: title === 'KDA'\n\t\t\t\t\t\t? `${(champion.kills / champion.picks).toFixed(2)} / ${(champion.deaths / champion.picks).toFixed(2)} / ${(champion.assists / champion.picks).toFixed(2)}`\n\t\t\t\t\t\t: title === 'Pick Rate'\n\t\t\t\t\t\t\t? `${champion.picks} / ${$store.state.totalGames - champion.bans}`\n\t\t\t\t\t\t\t: title === 'Ban Rate'\n\t\t\t\t\t\t\t\t? `${champion.bans} / ${$store.state.totalGames}`\n\t\t\t\t\t\t\t\t: null,\n\t\t\t\ttrigger: 'hover'\n\t\t\t}"}],key:index,attrs:{"data-champion":champion.championName},on:{"click":function($event){return _vm.handleChampionClick($event)}}},[_c('img',{attrs:{"src":("/images/championThumbs_50x50/" + (champion.championImgName) + ".png")}}),_vm._v(" "+_vm._s(champion.championName)+" "),(_vm.percent === 'yes')?_c('span',{staticClass:"champStatDivListSpan"},[_vm._v(_vm._s(champion[("" + _vm.rate)])+"%")]):_c('span',{staticClass:"champStatDivListSpan"},[_vm._v(_vm._s(champion[("" + _vm.rate)]))])])}),_c('p',{staticClass:"viewAllBtn",on:{"click":function($event){return _vm.testRoute(_vm.rate, 'desc')}}},[_c('span',[_vm._v("View all")])])],2)])}
var staticRenderFns = []

export { render, staticRenderFns }