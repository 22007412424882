/* eslint-disable no-eval */

<template>
	<div class="champStatDiv">
		<h3>{{ title }}</h3>
		<div class="champStatDivList" >
			<!-- Need to calculate things like KDA tooltip as per game averages instead of totals -->
			<p
				@click="handleChampionClick($event)"
				v-for="(champion, index) in data"
				:key="index"
				:data-champion="champion.championName"
				v-tooltip="{
					content: title === 'Win Rate'
						? `${champion.wins} W - ${champion.losses} L`
						: title === 'KDA'
							? `${(champion.kills / champion.picks).toFixed(2)} / ${(champion.deaths / champion.picks).toFixed(2)} / ${(champion.assists / champion.picks).toFixed(2)}`
							: title === 'Pick Rate'
								? `${champion.picks} / ${$store.state.totalGames - champion.bans}`
								: title === 'Ban Rate'
									? `${champion.bans} / ${$store.state.totalGames}`
									: null,
					trigger: 'hover'
				}"
			>
				<img :src="`/images/championThumbs_50x50/${champion.championImgName}.png`" /> {{ champion.championName }}
				<span v-if="percent === 'yes'" class="champStatDivListSpan" >{{ champion[`${rate}`] }}%</span >
				<span v-else class="champStatDivListSpan" >{{ champion[`${rate}`] }}</span >
			</p>
			<p class="viewAllBtn" @click="testRoute(rate, 'desc')">
				<span>View all</span>
			</p>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';

export default {
	name: 'page-champ-state',
	props: {
		handleChampionClick: {
			type: Function
		},
		testRoute: {
			type: Function
		},
		title: {
			type: String
		},
		data: {

		},
		rate: {
			type: String
		},
		percent: {
			type: String
		}
	},
	computed: {
		...mapState(['championVersion'])
	},
	methods: {
		testConsole () {
			console.log('hi', this.$props.data);
		}
	}
};

</script>

<style lang="scss">
	.champStatDivList p {
		cursor: pointer;
	}

	.tooltip {
	display: block !important;
	z-index: 10000;
	margin-right: 10%;
	border-bottom: none;

	.tooltip-inner {
		background: black;
		color: white;
		border-radius: 16px;
		padding: 5px 10px 4px;
	}

	.tooltip-arrow {
		width: 0;
		height: 0;
		border-style: solid;
		position: absolute;
		margin: 5px;
		border-color: black;
		z-index: 1;
	}

	&[aria-hidden="true"] {
		visibility: hidden;
		opacity: 0;
		transition: opacity 0.15s, visibility 0.15s;
	}

	&[aria-hidden="false"] {
		visibility: visible;
		opacity: 1;
		transition: opacity 0.15s;
	}
}

</style>
