<template>
	<div>
		<div v-if="isLoading">
			<div class="breeding-rhombus-spinner">
				<div class="rhombus child-1"></div>
				<div class="rhombus child-2"></div>
				<div class="rhombus child-3"></div>
				<div class="rhombus child-4"></div>
				<div class="rhombus child-5"></div>
				<div class="rhombus child-6"></div>
				<div class="rhombus child-7"></div>
				<div class="rhombus child-8"></div>
				<div class="rhombus big"></div>
			</div>
		</div>

		<div v-else>
			<nav-champ></nav-champ>

			<page-role-summary
				v-if="this.champion === 'all' && this.opponent === 'all'"
			></page-role-summary>
			<page-champ
				v-if="this.champion !== 'all' && this.opponent === 'all'"
				:champDataGrab="champDataGrab"
			></page-champ>
			<page-champ-opp
				v-if="this.champion === 'all' && this.opponent !== 'all'"
				:champDataGrab="champDataGrab"
			></page-champ-opp>
			<page-champ-vs
				v-if="this.champion !== 'all' && this.opponent !== 'all'"
				:champDataGrab="champDataGrab"
			></page-champ-vs>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';

import NavChamp from '../components/champion/_navChamp.vue';
import PageRoleSummary from '../components/champion/pageRoleSummary.vue';
import PageChamp from '../components/champion/pageChamp.vue';
import PageChampOpp from '../components/champion/pageChampOpp.vue';
import PageChampVs from '../components/champion/pageChampVs.vue';

export default {
	name: 'Champion',
	components: {
		NavChamp,
		PageRoleSummary,
		PageChamp,
		PageChampOpp,
		PageChampVs
	},
	data () {
		return {
			patch: '',
			totalGames: 0,
			championVersion: '',
			gameLimit: 1,
			champion: this.$route.params.champion || 'all',
			role: this.$route.params.role || 'all',
			opponent: (this.opponent = this.$route.params.opponent || 'all'),
			ddNav: {
				champs: [],
				roles: [],
				opponents: []
			},
			champDataGrab: {}
		};
	},
	created () {
		this.champion = this.champion.replace(/[\s.'&*+?^${}()|[\]\\]/g, '').toLowerCase();
		this.role = this.role.replace(/[\s.'&*+?^${}()|[\]\\]/g, '').toLowerCase();
		this.opponent = this.opponent.replace(/[\s.'&*+?^${}()|[\]\\]/g, '').toLowerCase();
		switch (this.role) {
			case 'jgl':
				this.role = 'jungle';
				break;
			case 'adc':
				this.role = 'bottom';
				break;
			default:
				break;
		}
		this.$store.dispatch('updateNav', {
			champion: this.champion,
			role: this.role,
			opponent: this.opponent
		});
		this.$store.dispatch('fetchChampData', {
			champion: this.champion,
			role: this.role,
			opponent: this.opponent
		});
	},
	computed: {
		...mapState(['isLoading'])
	}
};
</script>

<style lang="scss">
.hide {
	display: none;
}

// #dd-role-div {
// 	width: 298px;
// }

// #dd-champ-div {
// 	max-height: 325px;

// 	a {
// 		font-size: 16px;
// 		white-space: nowrap;
// 		text-align: left;
// 		padding: 2px 5px;
// 		height: 50px;

// 		img {
// 			width: 50px;
// 			height: 50px;
// 			padding: 0 5px 0 0;
// 		}
// 	}

// 	a:nth-child(1) {
// 		line-height: 54px;
// 		vertical-align: middle;
// 		text-align: center;
// 	}
// }

// #dd-opponent-div {
// 	max-height: 325px;

// 	a {
// 		font-size: 16px;
// 		white-space: nowrap;
// 		text-align: left;
// 		padding: 2px 5px;
// 		height: 50px;

// 		img {
// 			width: 50px;
// 			height: 50px;
// 			padding: 0 5px 0 0;
// 		}
// 	}

// 	a:nth-child(1) {
// 		line-height: 54px;
// 		vertical-align: middle;
// 		text-align: center;
// 	}
// }

#champion-index-div {
	margin: 25px auto 0 auto;
}

.champion-content-summary-div {
	margin: 25px auto 0 auto;
	grid-column: 1/5;
}

#roleStatSummary {
	display: grid;
	grid-template-columns: repeat(4, auto);
	width: 90%;
	margin: 25px auto 0 auto;

	.champStatDiv {
		text-align: left;
		margin: 0 auto;
		width: 375px;
		min-width: 250px;

		h3 {
			text-align: left;
			color: var(--primary-gold);
			padding: 3px 0 5px 10px;
			font-weight: normal;
		}

		.champStatDivList {
			background: var(--foreground);
			border-radius: 10px;
			padding: 10px 0;

			p {
				color: var(--white);
				margin: 10px 25px 10px 25px;

				span.champStatDivListSpan {
					color: var(--secondary-blue);
					padding-left: 5px;
				}
			}
		}

		img {
			width: 40px;
			vertical-align: middle;
			padding-right: 10px;
		}

		p:nth-last-child(1) {
			text-align: center;
			margin: 0 auto 0 auto;
			background: var(--secondary-blue);
			width: 50%;
			border-radius: 5px;
			height: 30px;
			line-height: 30px;
		}
	}
}

#championsDisplay {
	grid-column: 1/5;
	display: grid;
	grid-template-columns: repeat(10, auto);
	grid-column-gap: 15px;
	grid-row-gap: 25px;
	margin-top: 25px;

	.championDisplay {
		img {
			width: 50px;
		}
	}
}

span.highlightText {
	color: rgb(191, 154, 64);
}

.breeding-rhombus-spinner {
	height: 65px;
	width: 65px;
	position: fixed;
	transform: rotate(45deg);
	right: 50%;
	top: 50%;
}

.breeding-rhombus-spinner,
.breeding-rhombus-spinner * {
	box-sizing: border-box;
}

.breeding-rhombus-spinner .rhombus {
	height: calc(65px / 7.5);
	width: calc(65px / 7.5);
	animation-duration: 1250ms;
	top: calc(65px / 2.3077);
	left: calc(65px / 2.3077);
	background-color: var(--secondary-blue);
	position: absolute;
	animation-iteration-count: infinite;
}

.breeding-rhombus-spinner .rhombus:nth-child(2n + 0) {
	margin-right: 0;
}

.breeding-rhombus-spinner .rhombus.child-1 {
	animation-name: breeding-rhombus-spinner-animation-child-1;
	animation-delay: calc(100ms * 1);
}

.breeding-rhombus-spinner .rhombus.child-2 {
	animation-name: breeding-rhombus-spinner-animation-child-2;
	animation-delay: calc(100ms * 2);
}

.breeding-rhombus-spinner .rhombus.child-3 {
	animation-name: breeding-rhombus-spinner-animation-child-3;
	animation-delay: calc(100ms * 3);
}

.breeding-rhombus-spinner .rhombus.child-4 {
	animation-name: breeding-rhombus-spinner-animation-child-4;
	animation-delay: calc(100ms * 4);
}

.breeding-rhombus-spinner .rhombus.child-5 {
	animation-name: breeding-rhombus-spinner-animation-child-5;
	animation-delay: calc(100ms * 5);
}

.breeding-rhombus-spinner .rhombus.child-6 {
	animation-name: breeding-rhombus-spinner-animation-child-6;
	animation-delay: calc(100ms * 6);
}

.breeding-rhombus-spinner .rhombus.child-7 {
	animation-name: breeding-rhombus-spinner-animation-child-7;
	animation-delay: calc(100ms * 7);
}

.breeding-rhombus-spinner .rhombus.child-8 {
	animation-name: breeding-rhombus-spinner-animation-child-8;
	animation-delay: calc(100ms * 8);
}

.breeding-rhombus-spinner .rhombus.big {
	height: calc(65px / 3);
	width: calc(65px / 3);
	animation-duration: 2000ms;
	top: calc(65px / 3);
	left: calc(65px / 3);
	background-color: var(--secondary-blue);
	animation: breeding-rhombus-spinner-animation-child-big 2s infinite;
	animation-delay: 0.5s;
}

@keyframes breeding-rhombus-spinner-animation-child-1 {
	50% {
		transform: translate(-325%, -325%);
	}
}

@keyframes breeding-rhombus-spinner-animation-child-2 {
	50% {
		transform: translate(0, -325%);
	}
}

@keyframes breeding-rhombus-spinner-animation-child-3 {
	50% {
		transform: translate(325%, -325%);
	}
}

@keyframes breeding-rhombus-spinner-animation-child-4 {
	50% {
		transform: translate(325%, 0);
	}
}

@keyframes breeding-rhombus-spinner-animation-child-5 {
	50% {
		transform: translate(325%, 325%);
	}
}

@keyframes breeding-rhombus-spinner-animation-child-6 {
	50% {
		transform: translate(0, 325%);
	}
}

@keyframes breeding-rhombus-spinner-animation-child-7 {
	50% {
		transform: translate(-325%, 325%);
	}
}

@keyframes breeding-rhombus-spinner-animation-child-8 {
	50% {
		transform: translate(-325%, 0);
	}
}

@keyframes breeding-rhombus-spinner-animation-child-big {
	50% {
		transform: scale(0.5);
	}
}

.champ-nav-icon {
	font-size: 34px;
	height: 40px;
	margin-right: 10px;
	flex: 1.5;

	&.inactiveIcon svg {
		color: var(--grey-2) !important;
		opacity: .5;
	}

	&.activeIcon svg {
		color: var(--secondary-blue);
		opacity: 1;
	}

	&:hover svg {
		cursor: pointer;
		color: #FFCE61 !important;
		opacity: 1;
	}
}
</style>
