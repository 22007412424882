<template>
	<div id="site-nav-wrapper">
		<!-- <div id="theme-toggle-switch-div">
			<div>
				<input id="theme-toggle-switch" type="checkbox" onclick="toggleTheme()" />
			</div>
		</div> -->
		<nav id="site-nav">
			<img class="site-nav-logo" src="@/assets/img/logo/small/small-logo.png" alt="" />
			<router-link to="/" class="site-nav-link dark-theme" exact>
				<span></span><span></span><span></span><span></span>
				Home
			</router-link>
			<router-link to="/champion" class="site-nav-link dark-theme">
				<span></span><span></span><span></span><span></span>
				Champion
			</router-link>
			<!-- <router-link to="/summoner" class="site-nav-link dark-theme">
				<span></span><span></span><span></span><span></span>
				Summoner
			</router-link> -->
			<router-link to="#" class="site-nav-link dark-theme">
				<span></span><span></span><span></span><span></span>
				About
			</router-link>
		</nav>
		<div id="lm-discord">
			<a href="https://discord.gg/szcx3MJ" target="_blank">
				<font-awesome-icon :icon="['fab', 'discord']" />
			</a>
		</div>
	</div>
</template>

<script>
export default {
	name: 'nav-site'
};
</script>

<style lang="scss">
@import "@/assets/css/components/navigation.scss";
</style>
