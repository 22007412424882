<template>
	<div>
		<div
			v-show="this.view !== 'table'"
			id="champion-index-div"
		>
			<div>
				<div class="champStatSummary">
					<div class="champName">{{ getChampionData.championName }}</div>
					<div class="champLoadingImgDiv">
						<img :src="`/images/championLoading/${getChampionData.championImgName}_0.jpg`" />
					</div>
					<div class="pgChampStatsWrapper">
						<div class="pgChampStatsDiv">
							<h3>Win Rate</h3>
							<p>{{ getChampionData.winRate }}%</p>
						</div>
						<div class="pgChampStatsDiv">
							<h3>Pick Rate</h3>
							<p>{{ getChampionData.pickRate }}%</p>
						</div>
						<div class="pgChampStatsDiv">
							<h3>Ban Rate</h3>
							<p>{{ getChampionData.banRate }}%</p>
						</div>
						<div class="pgChampStatsDiv">
							<h3>KDA</h3>
							<p>{{ getChampionData.kda }}</p>
						</div>
					</div>
					<div class="pgChampStatsTableWrapper">
						<table class="pgChampStatsTable">
							<thead>
								<tr>
									<th></th>
									<th>Amount</th>
									<th>Games Per</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>Double Kill</td>
									<td>{{ getChampionData.doubleKills }}</td>
									<td>{{ getChampionData.gpDoubleKill }}</td>
								</tr>
								<tr>
									<td>Triple Kill</td>
									<td>{{ getChampionData.tripleKills }}</td>
									<td>{{ getChampionData.gpTripleKill }}</td>
								</tr>
								<tr>
									<td>Quadra Kill</td>
									<td>{{ getChampionData.quadraKills }}</td>
									<td>{{ getChampionData.gpQuadraKill }}</td>
								</tr>
								<tr>
									<td>Penta Kill</td>
									<td>{{ getChampionData.pentaKills }}</td>
									<td>{{ getChampionData.gpPentaKill }}</td>
								</tr>
								<tr>
									<td>First Blood</td>
									<td>
										{{ getChampionData.fbKills + getChampionData.fbAssists }}
									</td>
									<td>{{ getChampionData.gpFBKA }}</td>
								</tr>
								<tr>
									<td>First Death</td>
									<td>{{ getChampionData.fbDeaths }}</td>
									<td>{{ getChampionData.gpFBD }}</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>

				<div id="roleStatSummary">
					<page-champ-state
						title="Best Against"
						rate="winRate"
						v-bind:data="getBestAgainstSummary"
						:handleChampionClick="handleChampionClick"
						:testRoute="testRoute">
					</page-champ-state>
					<page-champ-state
						title="Worst Against"
						rate="winRate"
						v-bind:data="getWorstAgainstSummary"
						:handleChampionClick="handleChampionClick"
						:testRoute="testRoute">
					</page-champ-state>
					<page-champ-state
						title="Most Played Against"
						rate="pickRate"
						v-bind:data="getMostPlayedAgainstSummary"
						:handleChampionClick="handleChampionClick"
						:testRoute="testRoute">
					</page-champ-state>
				</div>
			</div>

			<div id="championsDisplay">
				<div
					@click="handleChampionClick($event)"
					class="championDisplay"
					v-for="(champion, index) in getChampionList"
					:key="index"
					:data-champion="champion.championName"
				>
					<img :src="`/images/championThumbs_50x50/${champion.championImgName}.png`" />
					<h4>{{ champion.championName }}</h4>
				</div>
			</div>
		</div>

		<champ-stats-table v-show="this.view === 'table'"></champ-stats-table>
	</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import ChampStatsTable from './champStatsTable.vue';
import pageChampState from './pageChampState.vue';

export default {
	name: 'page-champ',
	components: {
		ChampStatsTable,
		pageChampState
	},
	computed: {
		...mapState(['champDataGrab', 'championVersion', 'view']),
		...mapGetters([
			'getChampionList',
			'getChampionData',
			'getBestAgainstSummary',
			'getWorstAgainstSummary',
			'getMostPlayedAgainstSummary'
		])
	},
	methods: {
		testRoute (sortKey, sortDir) {
			this.$store.dispatch('setView', { sortKey,	sortDir });
		},
		handleChampionClick (e) {
			const champName = e.currentTarget.dataset.champion;
			this.$router.push({
				name: 'champion',
				params: { champion: this.getChampionData.championName, role: 'all', opponent: champName }
			});
		}
	}
};
</script>

<style lang="scss" scoped>
.champStatSummary {
	width: 75%;
	margin: 25px auto;
}

.champName {
	width: 100%;
	color: var(--white);
	text-align: left;
	font-size: 24px;
	margin: 0 0 10px 0;
}

.champLoadingImgDiv {
	margin: 0 10px 25px 0;
	height: 255px;
	width: 139px;
	display: inline-block;

	img {
		height: 255px;
		width: 139px;
		float: left;
	}
}

.championDisplay {
	cursor: pointer;
}

.pgChampStatsWrapper {
	width: 600px;
	float: left;

	.pgChampStatsDiv {
		background: var(--foreground);
		width: 226px;
		height: 117px;
		display: inline-block;
		margin: 5px 15px;
		border-radius: 10px;

		h3 {
			color: var(--primary-gold);
			font-size: 20px;
			font-weight: normal;
			margin: 15px auto;
		}

		p {
			color: var(--white);
			font-size: 32px;
			font-weight: normal;
		}
	}
}

.pgChampStatsTableWrapper {
	width: 450px;
	height: 245px;
	float: left;
	background: var(--foreground);
	margin: 5px 0;
	border-radius: 10px;

	table {
		width: 90%;
		margin: 40px auto;
		padding-right: 15px;
	}

	th {
		color: var(--primary-gold);
		font-size: 16px;
		font-weight: normal;
	}

	td {
		color: var(--white);
	}

	td:nth-child(1) {
		color: var(--grey-2);
		text-align: right;
	}
}

.champStatsTableDiv {
	margin: 10px 10px;
}

.champStatsLeftTable tbody tr td:nth-child(1) {
	text-align: right;
}

.champStatsLeftTable tbody tr td:nth-last-child(1) {
	text-align: left;
}

</style>
