import Vue from 'vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTrash, faTable, faSitemap } from '@fortawesome/free-solid-svg-icons';
import { faDiscord } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import VTooltip from 'v-tooltip';

import App from './App.vue';
import router from './router';
import store from './store/main.store';
import './assets/css/color-themes.scss';
import vuetify from './plugins/vuetify';

library.add(faTrash, faDiscord, faTable, faSitemap);
Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.use(VTooltip);
Vue.config.productionTip = false;

const vue = new Vue({
	render: (h) => h(App),
	router,
	vuetify,
	store
}).$mount('#app');

export default vue;
