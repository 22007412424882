import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const router = new Router({
	mode: 'history',
	routes: [
		{
			path: '/',
			name: 'home',
			component: () => import(/* webpackChunkName: "home" */ './views/Home.vue')
		},
		{
			path: '/champion/:champion?/:role?/:opponent?',
			name: 'champion',
			component: () => import(/* webpackChunkName: "champion" */ './views/Champion.vue')
		},
		{
			path: '/summoner/:summonerName?',
			name: 'summoner',
			component: () => import(/* webpackChunkName: "summoner" */ './views/Summoner.vue')
		},
		{
			path: '*',
			name: 'notfound',
			component: () => import('./views/404.vue')
		}
	]
});

export default router;
