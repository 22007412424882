<template>
	<div class="champ-nav-wrapper">
		<div class="champ-nav-item champ-nav-item-selectable">
			<h3><span class="dd-btn-txt">Champion</span></h3>
			<button class="dd-btn dd-btn-selectable">
				<span class="dd-btn-txt">{{ championText }}</span>
				<span class="dd-span"></span>
			</button>
			<div class="champ-nav-dd-wrapper" id="dd-champ-div">
				<div class="champ-nav-dd-search">
					<img :src="`/images/general/search_button.png`" />
					<input
						class="champ-nav-dd-search-text"
						type="text"
						placeholder="Search"
						v-model="searchTerm"
					/>
				</div>
				<div class="champ-nav-dd-content">
					<router-link
						:to="{
							name: 'champion',
							params: {
								champion: 'all',
								role: this.role,
								opponent: this.opponent,
							},
							query: $route.query,
						}"
					>
						All
					</router-link>
					<router-link
						v-for="(champion, index) in ddNav.champsSearch"
						:key="index"
						:to="{
							name: 'champion',
							params: {
								champion: champion.championName,
								role: role,
								opponent: opponent,
							},
							query: $route.query,
						}"
					>
						<img :src="`/images/championThumbs_50x50/${champion.championImgName}.png`" />
						{{ champion.championName }}
					</router-link>
				</div>
			</div>
		</div>
		<div class="champ-nav-item champ-nav-item-selectable">
			<h3><span class="dd-btn-txt">Role</span></h3>
			<button class="dd-btn dd-btn-selectable">
				<span class="dd-btn-txt">{{ roleText }}</span>
				<span class="dd-span"></span>
			</button>
			<div class="champ-nav-dd-wrapper" id="dd-role-div">
				<div class="champ-nav-dd-content">
					<router-link
						:to="{
							name: 'champion',
							params: {
								champion: this.champion,
								role: 'all',
								opponent: this.opponent,
							},
							query: $route.query,
						}"
					>
						All
					</router-link>
					<router-link
						v-for="(role, index) in ddNavRoles"
						:key="index"
						:to="{
							name: 'champion',
							params: { champion: champion, role: role, opponent: opponent },
							query: $route.query,
						}"
					>
						<img :src="`/images/roles/${role}-active.png`" alt="" />
						{{ role | roleTextFilter }}
					</router-link>
				</div>
			</div>
		</div>
		<div class="champ-nav-item champ-nav-item-selectable">
			<h3><span class="dd-btn-txt">Lane Opponent</span></h3>
			<button class="dd-btn dd-btn-selectable">
				<span class="dd-btn-txt">{{ laneOpponentText }}</span>
				<span class="dd-span"></span>
			</button>
			<div class="champ-nav-dd-wrapper" id="dd-opponent-div">
				<div class="champ-nav-dd-search">
					<img :src="`/images/general/search_button.png`" />
					<input
						class="champ-nav-dd-search-text"
						type="text"
						placeholder="Search"
						v-model="searchTermOpp"
					/>
				</div>
				<div class="champ-nav-dd-content">
					<router-link
						:to="{
							name: 'champion',
							params: {
								champion: this.champion,
								role: this.role,
								opponent: 'all',
							},
							query: $route.query,
						}"
					>
						All
					</router-link>
					<router-link
						v-for="(opponent, index) in ddNav.opponentsSearch"
						:key="index"
						:to="{
							name: 'champion',
							params: {
								champion: champion,
								role: role,
								opponent: opponent.championName,
							},
							query: $route.query,
						}"
					>
						<img :src="`/images/championThumbs_50x50/${opponent.championImgName}.png`" />
						{{ opponent.championName }}
					</router-link>
				</div>
			</div>
		</div>
		<div class="champ-nav-item champ-nav-info">
			<p>
				Patch: <span class="spanHL">{{ patch }}</span>
				<br>
				Matches: <span class="spanHL">{{ totalGames }}</span>
			</p>
		</div>
		<div class="champ-nav-item-table-func" v-if="champion === 'all' || opponent === 'all'">
			<div
				:class="[
					'champ-nav-item',
					'champ-nav-icon',
					this.view == 'all' ? 'inactiveIcon' : 'activeIcon',
				]"
			>
				<font-awesome-icon
					icon="table"
					v-tooltip="'Change View'"
					@click="toggleView('championName', 'asc')"
				/>
			</div>
			<div class="champ-nav-item-table-func-input">
				{{ gameLimitFilter }}
				<input
					type="range"
					:min="gameLimitMin"
					:max="gameLimitMax"
					@input="updateGameLimitFilter"
					:value="gameLimitFilter"
				/>
					{{ gameLimitMax }}
			</div>
		</div>
	</div>
</template>

<script>
import _ from 'lodash';
import { mapState } from 'vuex';

export default {
	name: 'nav-champ',
	data () {
		return {
			championText: '',
			roleText: '',
			laneOpponentText: '',
			searchTerm: '',
			searchTermOpp: ''
		};
	},
	computed: {
		...mapState([
			'champion',
			'role',
			'opponent',
			'patch',
			'totalGames',
			'ddNav',
			'championVersion',
			'view',
			'gameLimitMin',
			'gameLimitMax',
			'gameLimitFilter'
		]),
		// TODO: Probably best to put these computed functions as getters in vuex store
		ddNavChamps () {
			return _.orderBy(this.ddNav.champs, ['championName']);
		},
		ddNavLO () {
			return _.orderBy(this.ddNav.opponents, ['championName']);
		},
		ddNavRoles () {
			const tempNavRoles = this.ddNav.roles;
			return tempNavRoles.sort((a, b) => {
				if (a === 'support') {
					return 1;
				} else if (a === 'bottom' && b === 'support') {
					return -1;
				} else if (a === 'middle' && (b === 'support' || b === 'bottom')) {
					return -1;
				} else if (a === 'jungle' && (b === 'support' || b === 'bottom' || b === 'middle')) {
					return -1;
				} else if (a === 'top' && (b === 'support' || b === 'bottom' || b === 'middle' || b === 'jungle')) {
					return -1;
				} else if (b === 'top') {
					return 1;
				} else {
					return 0;
				}
			});
		}
	},
	methods: {
		toggleView (sortKey, sortDir) {
			this.$store.dispatch('setView', { sortKey, sortDir });
		},
		updateGameLimitFilter (e) {
			this.$store.dispatch('updateGameLimitFilter', { value: e.target.value });
		}
	},
	filters: {
		roleTextFilter (value) {
			const newValue = value.charAt(0).toUpperCase() + value.slice(1);
			return newValue;
		}
	},
	watch: {
		searchTerm () {
			this.$store.dispatch('ddNavSearch', { value: this.searchTerm });
		},
		searchTermOpp () {
			this.$store.dispatch('ddNavSearchOpp', { value: this.searchTermOpp });
		}
	},
	mounted () {
		this.championText = this.champion.charAt(0).toUpperCase() + this.champion.slice(1);
		this.roleText = this.role.charAt(0).toUpperCase() + this.role.slice(1);
		this.laneOpponentText = this.opponent.charAt(0).toUpperCase() + this.opponent.slice(1);
	}
};
</script>

<style lang="scss">
.champ-nav-wrapper {
	position: sticky;
	top: 58px;
	z-index: 5;
	text-align: center;
	margin: 100px auto 50px auto;
	border-radius: 10px;
	background: var(--dark-3);
	height: 63px;
	width: 60%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.champ-nav-item {
	display: inline-block;
	color: var(--white);
	font-size: 16px;
	flex: 5;

	h3 {
		display: inline-block;
		font-weight: normal;
		line-height: 62px;
	}
}

.champ-nav-icon svg {
	outline: none;
}

.champ-nav-item:nth-child(1) {
	border-radius: 10px 0 0 10px;
}

.champ-nav-item.champ-nav-item-selectable:hover,
.champ-nav-item.champ-nav-item-selectable:focus {
	background: darken(#101222, 5%);
}

button.dd-btn {
	position: relative;
	color: var(--secondary-blue);
	padding: 0 8px 0 10px;
	font-size: 16px;
	height: 30px;
	border: none;
	background: none;
	outline: none;

	.dd-btn-txt {
		padding-right: 25px;
	}

	img {
		height: 25px;
		vertical-align: middle;
	}
}

.dd-btn-txt {
	font-size: 16px;
	font-weight: normal;
}

.dd-btn.dd-btn-selectable::after {
	position: absolute;
	content: "";
	top: 37%;
	right: 0px;
	width: 0;
	height: 0;
	border: 8px solid transparent;
	border-color: var(--white) transparent transparent transparent;
}

.champ-nav-item-table-func {
  display: flex;
  border-left: 1px inset var(--grey-2);
  padding-left: 10px;
  padding-right: 10px;
}

.champ-nav-item-table-func-input {
	margin: auto;
}

#dd-champ-div {
	max-height: 325px;
	// border-radius: 10px 0 0 0;

	a {
		font-size: 12px;
		white-space: nowrap;
		text-align: left;
		padding: 0px 5px;
		height: 40px;

		img {
			width: 34px;
			height: 34px;
			margin: 3px 0;
			padding: 0 3px 0 0;
		}
	}

	a:nth-child(1) {
		line-height: 40px;
		vertical-align: middle;
		text-align: center;
	}
}

#dd-role-div {
	width: 250px;

	.champ-nav-dd-content {
		display: block;
		// width: 100%;
	}
}

#dd-opponent-div {
	max-height: 325px;

	a {
		font-size: 12px;
		white-space: nowrap;
		text-align: left;
		padding: 0px 5px;
		height: 40px;

		img {
			width: 34px;
			height: 34px;
			margin: 3px 0;
			padding: 0 3px 0 0;
		}
	}

	a:nth-child(1) {
		line-height: 40px;
		vertical-align: middle;
		text-align: center;
	}
}

.champ-nav-dd-wrapper {
	display: none;
	position: absolute;
	overflow: auto;
	z-index: 1;
	background: darken(#101222, 5%);
}

.champ-nav-dd-content {
	display: grid;
	grid-template-columns: repeat(3, auto);
	// position: absolute;
	// overflow: auto;
	// z-index: 1;
	// background: darken(#101222, 5%);
}

.champ-nav-dd-content a {
	color: var(--white);
	padding: 9px 13px;
	text-decoration: none;
	display: block;
	min-width: 100px;
	margin: 3px;
}

.champ-nav-dd-content a:hover {
	background: var(--secondary-blue);
}

.champ-nav-dd-content img {
	vertical-align: middle;
	height: 25px;
}

.champ-nav-item.champ-nav-item-selectable:hover #dd-champ-div,
.champ-nav-item.champ-nav-item-selectable:hover #dd-opponent-div,
.champ-nav-item.champ-nav-item-selectable:hover #dd-role-div {
	display: block;
	// grid-template-columns: repeat(3, auto);
}

.champ-nav-dd-search {
	height: 25px;
	border: 1px solid gray;
	margin: 5px;
	text-align: left;
	// border-radius: 10px 0 0 0;

	img {
		display: inline-block;
		margin: 2px 10px 2px 10px;
		vertical-align: middle;
		height: 16px;
		width: 16px;
	}

	.champ-nav-dd-search-text {
		display: inline-block;
		line-height: 25px;
		font-size: 12px;
		color: gray;
		width: calc(100% - 37px);
	}
}

input.champ-nav-dd-search-text {
	cursor: text;
	outline: none;
	// width: 100%;
}

.champ-nav-info {
	font-size: 12px;
	// line-height: 20px;
	color: var(--grey-2);
	text-align: right;
	border-left: 1px inset var(--grey-2);
	min-width: 150px;
	flex: 2;
	height: 40px;

	p {
		padding-right: 25px;
	}
}

span.spanHL {
	color: var(--secondary-blue);
}

.tooltip {
	display: block !important;
	z-index: 10000;

	.tooltip-inner {
		background: black;
		color: white;
		border-radius: 16px;
		padding: 5px 10px 4px;
	}

	.tooltip-arrow {
		width: 0;
		height: 0;
		border-style: solid;
		position: absolute;
		margin: 5px;
		border-color: black;
		z-index: 1;
	}

	&[aria-hidden="true"] {
		visibility: hidden;
		opacity: 0;
		transition: opacity 0.15s, visibility 0.15s;
	}

	&[aria-hidden="false"] {
		visibility: visible;
		opacity: 1;
		transition: opacity 0.15s;
	}
}
</style>
