import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import _ from 'lodash';

Vue.use(Vuex);

const store = new Vuex.Store({
	state: {
		isLoading: false,
		view: 'all',
		sortKey: 'championName',
		sortDir: 'asc',
		champion: 'all',
		role: 'all',
		opponent: 'all',
		patch: '',
		totalGames: '',
		gameLimitMin: 1,
		gameLimitMax: 500,
		gameLimitFilter: 1,
		championVersion: '',
		ddNav: {
			champs: [],
			champsSearch: [],
			roles: [],
			opponents: [],
			opponentsSearch: []
		},
		data: {},
		champDataGrab: {},
		champDataFiltered: {}
	},
	getters: {
		getChampionDataFiltered: (state) => _.filter(state.data.champions, (o) => (o.picks >= state.gameLimitMin)),
		getChampionList: (state) => _.orderBy(state.champDataFiltered, ['championName']),
		getChampionData: (state) => state.data.champion,
		getGameLimitMax: (state) => {
			const tempList = _.orderBy(state.data.champions, ['picks'], 'desc').slice(0, 1);

			if (tempList.length > 0) {
				return tempList[0].picks;
			}

			// If tempList is empty
			return state.data.champion.picks;
		},
		getGameLimitMin: (state) => {
			const tempList = _.orderBy(state.data.champions, ['picks'], 'asc').slice(0, 1);

			if (tempList.length > 0) {
				return tempList[0].picks;
			}

			// If tempList is empty
			return state.data.champion.picks;
		},
		getOpponentData: (state) => state.data.opponent,
		getPickRateSummary: (state) => _.orderBy(state.champDataFiltered, ['pickRate'], 'desc').slice(0, 5),
		getBanRateSummary: (state) => _.orderBy(state.champDataFiltered, ['banRate'], 'desc').slice(0, 5),
		getWinRateSummary: (state) => _.orderBy(state.champDataFiltered, ['winRate'], 'desc').slice(0, 5),
		getKDASummary: (state) => _.orderBy(_.filter(state.champDataFiltered, (o) => (o.picks >= 1)), ['kda'], 'desc').slice(0, 5),
		getBestAgainstSummary: (state) => _.orderBy(state.champDataFiltered, ['winRate'], 'desc').slice(0, 5),
		getWorstAgainstSummary: (state) => _.orderBy(state.champDataFiltered, ['winRate'], 'asc').slice(0, 5),
		getMostPlayedAgainstSummary: (state) => _.orderBy(state.champDataFiltered, ['pickRate'], 'desc').slice(0, 5)
	},
	// NOTE: for when we need to modify some data in our app
	mutations: {
		SET_NAV (state, payload) {
			state.champion = payload.champion || 'all';
			state.role = payload.role || 'all';
			state.opponent = payload.opponent || 'all';
		},
		SET_LOADING (state) {
			state.isLoading = true;
		},
		SET_VIEW (state, payload) {
			if (state.view === 'all') {
				state.view = 'table';
				state.sortKey = payload.sortKey;
			} else {
				state.view = 'all';
			}
		},
		SORT_TABLE (state, payload) {
			const oldKey = state.sortKey;
			const oldDir = state.sortDir;
			const newKey = payload.sortKey;
			if (oldKey === newKey) {
				if (oldDir === 'desc') {
					state.sortDir = 'asc';
				} else {
					state.sortDir = 'desc';
				}
			} else if ((newKey === 'gpDoubleKill') || (newKey === 'gpTripleKill') || (newKey === 'gpQuadraKill') || (newKey === 'gpPentaKill') || (newKey === 'gpFBKA')) {
				state.sortDir = 'asc';
			} else if (newKey === 'championName') {
				state.sortDir = 'asc';
			} else if ((newKey === 'dpm') || (newKey === 'deathShare')) {
				state.sortDir = 'asc';
			} else {
				state.sortDir = 'desc';
			}
			state.sortKey = newKey;
		},
		SET_CHAMP_DATA (state, payload) {
			state.patch = payload.patch;
			state.totalGames = payload.totalGames;
			state.championVersion = payload.championVersion;
			state.ddNav = payload.ddNav;
			state.ddNav.champs = _.orderBy(state.ddNav.champs, ['championName']);
			state.ddNav.champsSearch = state.ddNav.champs;
			state.ddNav.opponents = _.orderBy(state.ddNav.opponents, ['championName']);
			state.ddNav.opponentsSearch = state.ddNav.opponents;
			state.data = payload.data;
			state.champDataGrab = payload.data;
			// state.gameLimitFilter = this.getters.getGameLimitMin;
			state.gameLimitFilter = Math.floor(payload.totalGames * 0.005);
			// state.champDataFiltered = payload.data.champions;
			state.gameLimitMax = this.getters.getGameLimitMax;
			state.gameLimitMin = this.getters.getGameLimitMin;
			// Object.keys(payload.data)
			// 	.forEach((key) => {
			// 		console.log(key);
			// 	});
			if (Object.keys(payload.data)[0] === 'champion') {
				state.gameLimitMax = payload.data.champion.picks;
				const tempList = _.orderBy(payload.data.champions, ['picks'], 'asc').slice(0, 1);

				if (tempList.length > 0) {
					console.log(tempList[0].picks);
					state.gameLimitMin = tempList[0].picks;
				} else {
					state.gameLimitMin = 1;
				}
				state.gameLimitFilter = Math.floor(payload.data.champion.picks * 0.005);
				state.gameLimitFilter = (state.gameLimitFilter > 0) ? state.gameLimitFilter : 1;
			}
			state.champDataFiltered = _.filter(payload.data.champions, (o) => (o.picks >= state.gameLimitFilter));
			state.isLoading = false;
		},
		UPDATE_GAME_LIMIT_FILTER (state, payload) {
			state.gameLimitFilter = parseInt(payload);
			state.champDataFiltered = _.filter(state.data.champions, (o) => (o.picks >= state.gameLimitFilter));
		},
		SEARCH_DD_NAV (state, payload) {
			state.ddNav.champsSearch = _.orderBy(_.filter(state.ddNav.champs, (o) => (o.championName.toLowerCase().match(payload.toLowerCase()))), ['championName']);
		},
		SEARCH_DD_NAV_OPP (state, payload) {
			state.ddNav.opponentsSearch = _.orderBy(_.filter(state.ddNav.opponents, (o) => (o.championName.toLowerCase().match(payload.toLowerCase()))), ['championName']);
		}
	},
	// NOTE: functions that can use mutations to modify a state
	actions: {
		setView (context, payload) { context.commit('SET_VIEW', payload); },
		sortTable (context, payload) { context.commit('SORT_TABLE', payload); },
		updateNav (context, payload) {
			const newPayload = {
				champion: payload.champion,
				role: payload.role,
				opponent: payload.opponent
			};
			context.commit('SET_NAV', newPayload);
		},
		fetchChampData (context, payload) {
			context.commit('SET_LOADING');
			axios({
				method: 'get',
				// TODO: Need to set `baseURL` based on dev/prod env
				// baseURL: 'http://localhost:3000/api/',
				baseURL: 'https://api.leaguemetrics.lol/api/',
				url: `/champion/${payload.champion}/${payload.role}/${payload.opponent}`,
				// url: 'http://localhost:8080/data/champion/all-all-all.json',
				headers: { 'Key-Token-Test': '89jg98w4jg945j5gij49g5' }
			}).then((response) => {
				context.commit('SET_CHAMP_DATA', response.data);
			}).catch((error) => console.log(error));
		},
		updateGameLimitFilter (context, payload) {
			context.commit('UPDATE_GAME_LIMIT_FILTER', payload.value);
		},
		ddNavSearch (context, payload) {
			context.commit('SEARCH_DD_NAV', payload.value);
		},
		ddNavSearchOpp (context, payload) {
			context.commit('SEARCH_DD_NAV_OPP', payload.value);
		}
	}
});

export default store;
