<template>
	<div class="champ-stats-table-div">
		<div class="heightTester"></div>
		<div id="buttonGroup" class="buttonGroup">
			<div
				class="tableSelectButton"
				:class="[(this.selectedTable === 'general') ? ['active'] : '']"
				@click="selectedTable = 'general'"
			>General Stats</div>
			<div
				class="tableSelectButton"
				:class="[(this.selectedTable === 'dmgHeal') ? ['active'] : '']"
				@click="selectedTable = 'dmgHeal'; sortKeyChange('championName')"
			>Dmg & Heal</div>
			<div
				class="tableSelectButton"
				:class="[(this.selectedTable === 'cs') ? ['active'] : '']"
				@click="selectedTable = 'cs'; sortKeyChange('championName')"
			>CS</div>
			<div
				class="tableSelectButton"
				:class="[(this.selectedTable === 'gold') ? ['active'] : '']"
				@click="selectedTable = 'gold'; sortKeyChange('championName')"
			>Gold</div>
			<div
				class="tableSelectButton"
				:class="[(this.selectedTable === 'xp') ? ['active'] : '']"
				@click="selectedTable = 'xp'; sortKeyChange('championName')"
			>XP</div>
			<div
				class="tableSelectButton"
				:class="[(this.selectedTable === 'killType') ? ['active'] : '']"
				@click="selectedTable = 'killType'; sortKeyChange('championName')"
			>Kill Types</div>
			<div
				class="tableSelectButton"
				:class="[(this.selectedTable === 'obj') ? ['active'] : '']"
				@click="selectedTable = 'obj'; sortKeyChange('championName')"
			>Objectives</div>
		</div>

		<!-- TODO: Look into using v-show instead of v-if for the tables to avoid destroying/creating them repeatedly when switching tables -->
		<table class="tablesorter champStatsMainTable generalTable" v-if="this.selectedTable === 'general'">
			<thead>
				<tr>
					<th
						id="championName"
						@click="sortKeyChange('championName')"
						:class="[
							(this.sortKey === 'championName') ? ['sorted', 'primary'] : '',
							(this.sortKey === 'championName' && this.sortDir === 'asc') ? 'desc' : ''
						]"
					>Name</th>
					<th
						id="picks"
						@click="sortKeyChange('picks')"
						:class="[(this.sortKey === 'picks') ? ['sorted', this.sortDir, 'primary'] : '']"
					>Games</th>
					<th
						id="winRate"
						@click="sortKeyChange('winRate')"
						:class="[(this.sortKey === 'winRate') ? ['sorted', this.sortDir, 'primary'] : '']"
					>Win %</th>
					<th
						id="pickRate"
						@click="sortKeyChange('pickRate')"
						:class="[(this.sortKey === 'pickRate') ? ['sorted', this.sortDir, 'primary'] : '']"
					>Pick Rate</th>
					<th
						id="banRate"
						@click="sortKeyChange('banRate')"
						:class="[(this.sortKey === 'banRate') ? ['sorted', this.sortDir, 'primary'] : '']"
					>Ban Rate</th>
					<th
						id="avgDuration"
						@click="sortKeyChange('avgDuration')"
						:class="[(this.sortKey === 'avgDuration') ? ['sorted', this.sortDir, 'primary'] : '']"
					>Avg.<br>Duration</th>
					<th
						id="kda"
						@click="sortKeyChange('kda')"
						:class="[(this.sortKey === 'kda') ? ['sorted', this.sortDir, 'primary'] : '']"
					>KDA</th>
					<th
						id="kpm"
						@click="sortKeyChange('kpm')"
						:class="[(this.sortKey === 'kpm') ? ['sorted', this.sortDir, 'primary'] : '']"
					>Kills/Min</th>
					<th
						id="killShare"
						@click="sortKeyChange('killShare')"
						:class="[(this.sortKey === 'killShare') ? ['sorted', this.sortDir, 'primary'] : '']"
					>Kill<br>Share</th>
					<th
						id="dpm"
						@click="sortKeyChange('dpm')"
						:class="[(this.sortKey === 'dpm') ? ['sorted', ((this.sortDir === 'asc') ? 'desc' : 'asc'), 'primary'] : '']"
					>Deaths/Min</th>
					<th
						id="deathShare"
						@click="sortKeyChange('deathShare')"
						:class="[(this.sortKey === 'deathShare') ? ['sorted', ((this.sortDir === 'asc') ? 'desc' : 'asc'), 'primary'] : '']"
					>Death<br>Share</th>
					<th
						id="apm"
						@click="sortKeyChange('apm')"
						:class="[(this.sortKey === 'apm') ? ['sorted', this.sortDir, 'primary'] : '']"
					>Assists/Min</th>
					<th
						id="kp"
						@click="sortKeyChange('kp')"
						:class="[(this.sortKey === 'kp') ? ['sorted', this.sortDir, 'primary'] : '']"
					>Kill<br>Part.</th>
				</tr>
			</thead>
			<tbody>
				<tr
					v-for="(champion, index) in sortedChamps"
					:key="index"
					:class="evenOdd(index)"
				>
					<td :class="{'primary':(sortKey === 'championName')}">
						<span class="champTableName">
							<img :src="`/images/championThumbs_50x50/${champion.championImgName}.png`" alt="" />
							<span class="level">{{champion.championName}}</span>
						</span>
					</td>
					<td :class="{'primary':(sortKey === 'picks')}">{{champion.picks}}</td>
					<td :class="{'primary':(sortKey === 'winRate')}">{{champion.winRate}}%</td>
					<td :class="{'primary':(sortKey === 'pickRate')}">{{champion.pickRate}}%</td>
					<td :class="{'primary':(sortKey === 'banRate')}">{{champion.banRate}}%</td>
					<td :class="{'primary':(sortKey === 'avgDuration')}">{{champion.avgDuration}}</td>
					<td :class="{'primary':(sortKey === 'kda')}">{{champion.kda}}</td>
					<td :class="{'primary':(sortKey === 'kpm')}">{{champion.kpm}}</td>
					<td :class="{'primary':(sortKey === 'killShare')}">{{champion.killShare}}%</td>
					<td :class="{'primary':(sortKey === 'dpm')}">{{champion.dpm}}</td>
					<td :class="{'primary':(sortKey === 'deathShare')}">{{champion.deathShare}}%</td>
					<td :class="{'primary':(sortKey === 'apm')}">{{champion.apm}}</td>
					<td :class="{'primary':(sortKey === 'kp')}">{{champion.kp}}%</td>
				</tr>
			</tbody>
		</table>

		<table class="tablesorter champStatsMainTable dmgHealTable" v-if="this.selectedTable === 'dmgHeal'">
			<thead>
				<tr>
					<th
						id="championName"
						@click="sortKeyChange('championName')"
						:class="[
							(this.sortKey === 'championName') ? ['sorted', 'primary'] : '',
							(this.sortKey === 'championName' && this.sortDir === 'asc') ? 'desc' : ''
						]"
					>Name</th>
					<th
						id="picks"
						@click="sortKeyChange('picks')"
						:class="[(this.sortKey === 'picks') ? ['sorted', this.sortDir, 'primary'] : '']"
					>Games</th>
					<th
						id="winRate"
						@click="sortKeyChange('winRate')"
						:class="[(this.sortKey === 'winRate') ? ['sorted', this.sortDir, 'primary'] : '']"
					>Win %</th>
					<th
						id="dmgShare"
						@click="sortKeyChange('dmgShare')"
						:class="[(this.sortKey === 'dmgShare') ? ['sorted', this.sortDir, 'primary'] : '']"
					>Dmg Share</th>
					<th
						id="dmgPM"
						@click="sortKeyChange('dmgPM')"
						:class="[(this.sortKey === 'dmgPM') ? ['sorted', this.sortDir, 'primary'] : '']"
					>Dmg PM</th>
					<th
						id="physDmgPM"
						@click="sortKeyChange('physDmgPM')"
						:class="[(this.sortKey === 'physDmgPM') ? ['sorted', this.sortDir, 'primary'] : '']"
						v-tooltip="{
							content: 'Physical Damage Per Minute',
							trigger: 'hover'
						}"
					>PDMG PM</th>
					<th
						id="mgcDmgPM"
						@click="sortKeyChange('mgcDmgPM')"
						:class="[(this.sortKey === 'mgcDmgPM') ? ['sorted', this.sortDir, 'primary'] : '']"
					>MDMG PM</th>
					<th
						id="trueDmgPM"
						@click="sortKeyChange('trueDmgPM')"
						:class="[(this.sortKey === 'trueDmgPM') ? ['sorted', this.sortDir, 'primary'] : '']"
					>TDMG PM</th>
					<th
						id="dmgTPM"
						@click="sortKeyChange('dmgTPM')"
						:class="[(this.sortKey === 'dmgTPM') ? ['sorted', this.sortDir, 'primary'] : '']"
					>Dmg TPM</th>
					<th
						id="physDmgTPM"
						@click="sortKeyChange('physDmgTPM')"
						:class="[(this.sortKey === 'physDmgTPM') ? ['sorted', this.sortDir, 'primary'] : '']"
					>PDMG TPM</th>
					<th
						id="mgcDmgTPM"
						@click="sortKeyChange('mgcDmgTPM')"
						:class="[(this.sortKey === 'mgcDmgTPM') ? ['sorted', this.sortDir, 'primary'] : '']"
					>MDMG TPM</th>
					<th
						id="trueDmgTPM"
						@click="sortKeyChange('trueDmgTPM')"
						:class="[(this.sortKey === 'trueDmgTPM') ? ['sorted', this.sortDir, 'primary'] : '']"
					>TDMG TPM</th>
					<th
						id="healPM"
						@click="sortKeyChange('healPM')"
						:class="[(this.sortKey === 'healPM') ? ['sorted', this.sortDir, 'primary'] : '']"
					>Heal PM</th>
					<th
						id="ccPM"
						@click="sortKeyChange('ccPM')"
						:class="[(this.sortKey === 'ccPM') ? ['sorted', this.sortDir, 'primary'] : '']"
					>CC PM</th>
					<th
						id="dmgSMPM"
						@click="sortKeyChange('dmgSMPM')"
						:class="[(this.sortKey === 'dmgSMPM') ? ['sorted', this.sortDir, 'primary'] : '']"
					>Dmg Mit PM</th>
				</tr>
			</thead>
			<tbody>
				<tr
					v-for="(champion, index) in sortedChamps"
					:key="index"
					:class="evenOdd(index)"
				>
					<td :class="{'primary':(sortKey === 'championName')}">
						<span class="champTableName">
							<img :src="`/images/championThumbs_50x50/${champion.championImgName}.png`" alt="" />
							<span class="level">{{champion.championName}}</span>
						</span>
					</td>
					<td :class="{'primary':(sortKey === 'picks')}">{{champion.picks}}</td>
					<td :class="{'primary':(sortKey === 'winRate')}">{{champion.winRate}}%</td>
					<td :class="{'primary':(sortKey === 'dmgShare')}">{{champion.dmgShare}}%</td>
					<td :class="{'primary':(sortKey === 'dmgPM')}">{{champion.dmgPM}}</td>
					<td :class="{'primary':(sortKey === 'physDmgPM')}">{{champion.physDmgPM}}</td>
					<td :class="{'primary':(sortKey === 'mgcDmgPM')}">{{champion.mgcDmgPM}}</td>
					<td :class="{'primary':(sortKey === 'trueDmgPM')}">{{champion.trueDmgPM}}</td>
					<td :class="{'primary':(sortKey === 'dmgTPM')}">{{champion.dmgTPM}}</td>
					<td :class="{'primary':(sortKey === 'physDmgTPM')}">{{champion.physDmgTPM}}</td>
					<td :class="{'primary':(sortKey === 'mgcDmgTPM')}">{{champion.mgcDmgTPM}}</td>
					<td :class="{'primary':(sortKey === 'trueDmgTPM')}">{{champion.trueDmgTPM}}</td>
					<td :class="{'primary':(sortKey === 'healPM')}">{{champion.healPM}}</td>
					<td :class="{'primary':(sortKey === 'ccPM')}">{{champion.ccPM}}</td>
					<td :class="{'primary':(sortKey === 'dmgSMPM')}">{{champion.dmgSMPM}}</td>
				</tr>
			</tbody>
		</table>

		<table class="tablesorter champStatsMainTable csTable" v-if="this.selectedTable === 'cs'">
			<thead>
				<tr>
					<th
						id="championName"
						@click="sortKeyChange('championName')"
						:class="[
							(this.sortKey === 'championName') ? ['sorted', 'primary'] : '',
							(this.sortKey === 'championName' && this.sortDir === 'asc') ? 'desc' : ''
						]"
					>Name</th>
					<th
						id="picks"
						@click="sortKeyChange('picks')"
						:class="[(this.sortKey === 'picks') ? ['sorted', this.sortDir, 'primary'] : '']"
					>Games</th>
					<th
						id="winRate"
						@click="sortKeyChange('winRate')"
						:class="[(this.sortKey === 'winRate') ? ['sorted', this.sortDir, 'primary'] : '']"
					>Win %</th>
					<th
						id="csShare"
						@click="sortKeyChange('csShare')"
						:class="[(this.sortKey === 'csShare') ? ['sorted', this.sortDir, 'primary'] : '']"
					>CS Share</th>
					<th
						id="cspm"
						@click="sortKeyChange('cspm')"
						:class="[(this.sortKey === 'cspm') ? ['sorted', this.sortDir, 'primary'] : '']"
					>CSPM</th>
					<th
						id="cspm10"
						@click="sortKeyChange('cspm10')"
						:class="[(this.sortKey === 'cspm10') ? ['sorted', this.sortDir, 'primary'] : '']"
					>CSPM<br>0-10</th>
					<th
						id="cspm20"
						@click="sortKeyChange('cspm20')"
						:class="[(this.sortKey === 'cspm20') ? ['sorted', this.sortDir, 'primary'] : '']"
					>CSPM<br>10-20</th>
					<th
						id="cspm30"
						@click="sortKeyChange('cspm30')"
						:class="[(this.sortKey === 'cspm30') ? ['sorted', this.sortDir, 'primary'] : '']"
					>CSPM<br>20-30</th>
					<th
						id="cspmEnd"
						@click="sortKeyChange('cspmEnd')"
						:class="[(this.sortKey === 'cspmEnd') ? ['sorted', this.sortDir, 'primary'] : '']"
					>CSPM<br>30-End</th>
					<th
						id="cspmd10"
						@click="sortKeyChange('cspmd10')"
						:class="[(this.sortKey === 'cspmd10') ? ['sorted', this.sortDir, 'primary'] : '']"
					>CSDPM<br>0-10</th>
					<th
						id="cspmd20"
						@click="sortKeyChange('cspmd20')"
						:class="[(this.sortKey === 'cspmd20') ? ['sorted', this.sortDir, 'primary'] : '']"
					>CSDPM<br>10-20</th>
					<th
						id="cspmd30"
						@click="sortKeyChange('cspmd30')"
						:class="[(this.sortKey === 'cspmd30') ? ['sorted', this.sortDir, 'primary'] : '']"
					>CSDPM<br>20-30</th>
					<th
						id="cspmdEnd"
						@click="sortKeyChange('cspmdEnd')"
						:class="[(this.sortKey === 'cspmdEnd') ? ['sorted', this.sortDir, 'primary'] : '']"
					>CSDPM<br>30-End</th>
					<!-- <th
						id="jcspmAll"
						@click="sortKeyChange('jcspmAll')"
						:class="[(this.sortKey === 'jcspmAll') ? ['sorted', this.sortDir, 'primary'] : '']"
					>JCSPM All</th>
					<th
						id="jcspmOwn"
						@click="sortKeyChange('jcspmOwn')"
						:class="[(this.sortKey === 'jcspmOwn') ? ['sorted', this.sortDir, 'primary'] : '']"
					>JCSPM Own</th>
					<th
						id="jcspmEnemy"
						@click="sortKeyChange('jcspmEnemy')"
						:class="[(this.sortKey === 'jcspmEnemy') ? ['sorted', this.sortDir, 'primary'] : '']"
					>JCSPM Enemy</th> -->
				</tr>
			</thead>
			<tbody>
				<tr
					v-for="(champion, index) in sortedChamps"
					:key="index"
					:class="evenOdd(index)"
				>
					<td :class="{'primary':(sortKey === 'championName')}">
						<span class="champTableName">
							<img :src="`/images/championThumbs_50x50/${champion.championImgName}.png`" alt="" />
							<span class="level">{{champion.championName}}</span>
						</span>
					</td>
					<td :class="{'primary':(sortKey === 'picks')}">{{champion.picks}}</td>
					<td :class="{'primary':(sortKey === 'winRate')}">{{champion.winRate}}%</td>
					<td :class="{'primary':(sortKey === 'csShare')}">{{champion.csShare}}%</td>
					<td :class="{'primary':(sortKey === 'cspm')}">{{champion.cspm}}</td>
					<td :class="{'primary':(sortKey === 'cspm10')}">{{champion.cspm10}}</td>
					<td :class="{'primary':(sortKey === 'cspm20')}">{{champion.cspm20}}</td>
					<td :class="{'primary':(sortKey === 'cspm30')}">{{champion.cspm30}}</td>
					<td :class="{'primary':(sortKey === 'cspmEnd')}">{{champion.cspmEnd}}</td>
					<td :class="{'primary':(sortKey === 'cspmd10')}">{{champion.cspmd10}}</td>
					<td :class="{'primary':(sortKey === 'cspmd20')}">{{champion.cspmd20}}</td>
					<td :class="{'primary':(sortKey === 'cspmd30')}">{{champion.cspmd30}}</td>
					<td :class="{'primary':(sortKey === 'cspmdEnd')}">{{champion.cspmdEnd}}</td>
					<!-- <td :class="{'primary':(sortKey === 'jcspmAll')}">{{champion.jcspmAll}}</td>
					<td :class="{'primary':(sortKey === 'jcspmOwn')}">{{champion.jcspmOwn}}</td>
					<td :class="{'primary':(sortKey === 'jcspmEnemy')}">{{champion.jcspmEnemy}}</td> -->
				</tr>
			</tbody>
		</table>

		<table class="tablesorter champStatsMainTable goldTable" v-if="this.selectedTable === 'gold'">
			<thead>
				<tr>
					<th
						id="championName"
						@click="sortKeyChange('championName')"
						:class="[
							(this.sortKey === 'championName') ? ['sorted', 'primary'] : '',
							(this.sortKey === 'championName' && this.sortDir === 'asc') ? 'desc' : ''
						]"
					>Name</th>
					<th
						id="picks"
						@click="sortKeyChange('picks')"
						:class="[(this.sortKey === 'picks') ? ['sorted', this.sortDir, 'primary'] : '']"
					>Games</th>
					<th
						id="winRate"
						@click="sortKeyChange('winRate')"
						:class="[(this.sortKey === 'winRate') ? ['sorted', this.sortDir, 'primary'] : '']"
					>Win %</th>
					<th
						id="goldShare"
						@click="sortKeyChange('goldShare')"
						:class="[(this.sortKey === 'goldShare') ? ['sorted', this.sortDir, 'primary'] : '']"
					>Gold Share</th>
					<th
						id="gpm"
						@click="sortKeyChange('gpm')"
						:class="[(this.sortKey === 'gpm') ? ['sorted', this.sortDir, 'primary'] : '']"
					>GPM</th>
					<th
						id="gpm10"
						@click="sortKeyChange('gpm10')"
						:class="[(this.sortKey === 'gpm10') ? ['sorted', this.sortDir, 'primary'] : '']"
					>GPM<br>0-10</th>
					<th
						id="gpm20"
						@click="sortKeyChange('gpm20')"
						:class="[(this.sortKey === 'gpm20') ? ['sorted', this.sortDir, 'primary'] : '']"
					>GPM<br>10-20</th>
					<th
						id="gpm30"
						@click="sortKeyChange('gpm30')"
						:class="[(this.sortKey === 'gpm30') ? ['sorted', this.sortDir, 'primary'] : '']"
					>GPM<br>20-30</th>
					<th
						id="gpmEnd"
						@click="sortKeyChange('gpmEnd')"
						:class="[(this.sortKey === 'gpmEnd') ? ['sorted', this.sortDir, 'primary'] : '']"
					>GPM<br>30-End</th>
					<th
						id="gpmd10"
						@click="sortKeyChange('gpmd10')"
						:class="[(this.sortKey === 'gpmd10') ? ['sorted', this.sortDir, 'primary'] : '']"
					>GPMD<br>0-10</th>
					<th
						id="gpmd20"
						@click="sortKeyChange('gpmd20')"
						:class="[(this.sortKey === 'gpmd20') ? ['sorted', this.sortDir, 'primary'] : '']"
					>GPMD<br>10-20</th>
					<th
						id="gpmd30"
						@click="sortKeyChange('gpmd30')"
						:class="[(this.sortKey === 'gpmd30') ? ['sorted', this.sortDir, 'primary'] : '']"
					>GPMD<br>20-30</th>
					<th
						id="gpmdEnd"
						@click="sortKeyChange('gpmdEnd')"
						:class="[(this.sortKey === 'gpmdEnd') ? ['sorted', this.sortDir, 'primary'] : '']"
					>GPMD<br>30-End</th>
					<!-- <th
						id="xppm10"
						@click="sortKeyChange('xppm10')"
						:class="[(this.sortKey === 'xppm10') ? ['sorted', this.sortDir, 'primary'] : '']"
					>XPPM<br>0-10</th>
					<th
						id="xppm20"
						@click="sortKeyChange('xppm20')"
						:class="[(this.sortKey === 'xppm20') ? ['sorted', this.sortDir, 'primary'] : '']"
					>XPPM<br>10-20</th>
					<th
						id="xppm30"
						@click="sortKeyChange('xppm30')"
						:class="[(this.sortKey === 'xppm30') ? ['sorted', this.sortDir, 'primary'] : '']"
					>XPPM<br>20-30</th>
					<th
						id="xppmEnd"
						@click="sortKeyChange('xppmEnd')"
						:class="[(this.sortKey === 'xppmEnd') ? ['sorted', this.sortDir, 'primary'] : '']"
					>XPPM<br>30-End</th>
					<th
						id="xppmd10"
						@click="sortKeyChange('xppmd10')"
						:class="[(this.sortKey === 'xppmd10') ? ['sorted', this.sortDir, 'primary'] : '']"
					>XPDPM<br>0-10</th>
					<th
						id="xppmd20"
						@click="sortKeyChange('xppmd20')"
						:class="[(this.sortKey === 'xppmd20') ? ['sorted', this.sortDir, 'primary'] : '']"
					>XPDPM<br>10-20</th>
					<th
						id="xppmd30"
						@click="sortKeyChange('xppmd30')"
						:class="[(this.sortKey === 'xppmd30') ? ['sorted', this.sortDir, 'primary'] : '']"
					>XPDPM<br>20-30</th>
					<th
						id="xppmdEnd"
						@click="sortKeyChange('xppmdEnd')"
						:class="[(this.sortKey === 'xppmdEnd') ? ['sorted', this.sortDir, 'primary'] : '']"
					>XPDPM<br>30-End</th> -->
				</tr>
			</thead>
			<tbody>
				<tr
					v-for="(champion, index) in sortedChamps"
					:key="index"
					:class="evenOdd(index)"
				>
					<td :class="{'primary':(sortKey === 'championName')}">
						<span class="champTableName">
							<img :src="`/images/championThumbs_50x50/${champion.championImgName}.png`" alt="" />
							<span class="level">{{champion.championName}}</span>
						</span>
					</td>
					<td :class="{'primary':(sortKey === 'picks')}">{{champion.picks}}</td>
					<td :class="{'primary':(sortKey === 'winRate')}">{{champion.winRate}}%</td>
					<td :class="{'primary':(sortKey === 'goldShare')}">{{champion.goldShare}}%</td>
					<td :class="{'primary':(sortKey === 'gpm')}">{{champion.gpm}}</td>
					<td :class="{'primary':(sortKey === 'gpm10')}">{{champion.gpm10}}</td>
					<td :class="{'primary':(sortKey === 'gpm20')}">{{champion.gpm20}}</td>
					<td :class="{'primary':(sortKey === 'gpm30')}">{{champion.gpm30}}</td>
					<td :class="{'primary':(sortKey === 'gpmEnd')}">{{champion.gpmEnd}}</td>
					<td :class="{'primary':(sortKey === 'gpmd10')}">{{champion.gpmd10}}</td>
					<td :class="{'primary':(sortKey === 'gpmd20')}">{{champion.gpmd20}}</td>
					<td :class="{'primary':(sortKey === 'gpmd30')}">{{champion.gpmd30}}</td>
					<td :class="{'primary':(sortKey === 'gpmdEnd')}">{{champion.gpmdEnd}}</td>
					<!-- <td :class="{'primary':(sortKey === 'xppm10')}">{{champion.xppm10}}</td>
					<td :class="{'primary':(sortKey === 'xppm20')}">{{champion.xppm20}}</td>
					<td :class="{'primary':(sortKey === 'xppm30')}">{{champion.xppm30}}</td>
					<td :class="{'primary':(sortKey === 'xppmEnd')}">{{champion.xppmEnd}}</td>
					<td :class="{'primary':(sortKey === 'xppmd10')}">{{champion.xppmd10}}</td>
					<td :class="{'primary':(sortKey === 'xppmd20')}">{{champion.xppmd20}}</td>
					<td :class="{'primary':(sortKey === 'xppmd30')}">{{champion.xppmd30}}</td>
					<td :class="{'primary':(sortKey === 'xppmdEnd')}">{{champion.xppmdEnd}}</td> -->
				</tr>
			</tbody>
		</table>

		<table class="tablesorter champStatsMainTable xpTable" v-if="this.selectedTable === 'xp'">
			<thead>
				<tr>
					<th
						id="championName"
						@click="sortKeyChange('championName')"
						:class="[
							(this.sortKey === 'championName') ? ['sorted', 'primary'] : '',
							(this.sortKey === 'championName' && this.sortDir === 'asc') ? 'desc' : ''
						]"
					>Name</th>
					<th
						id="picks"
						@click="sortKeyChange('picks')"
						:class="[(this.sortKey === 'picks') ? ['sorted', this.sortDir, 'primary'] : '']"
					>Games</th>
					<th
						id="winRate"
						@click="sortKeyChange('winRate')"
						:class="[(this.sortKey === 'winRate') ? ['sorted', this.sortDir, 'primary'] : '']"
					>Win %</th>
					<th
						id="xppm10"
						@click="sortKeyChange('xppm10')"
						:class="[(this.sortKey === 'xppm10') ? ['sorted', this.sortDir, 'primary'] : '']"
					>XPPM<br>0-10</th>
					<th
						id="xppm20"
						@click="sortKeyChange('xppm20')"
						:class="[(this.sortKey === 'xppm20') ? ['sorted', this.sortDir, 'primary'] : '']"
					>XPPM<br>10-20</th>
					<th
						id="xppm30"
						@click="sortKeyChange('xppm30')"
						:class="[(this.sortKey === 'xppm30') ? ['sorted', this.sortDir, 'primary'] : '']"
					>XPPM<br>20-30</th>
					<th
						id="xppmEnd"
						@click="sortKeyChange('xppmEnd')"
						:class="[(this.sortKey === 'xppmEnd') ? ['sorted', this.sortDir, 'primary'] : '']"
					>XPPM<br>30-End</th>
					<th
						id="xppmd10"
						@click="sortKeyChange('xppmd10')"
						:class="[(this.sortKey === 'xppmd10') ? ['sorted', this.sortDir, 'primary'] : '']"
					>XPDPM<br>0-10</th>
					<th
						id="xppmd20"
						@click="sortKeyChange('xppmd20')"
						:class="[(this.sortKey === 'xppmd20') ? ['sorted', this.sortDir, 'primary'] : '']"
					>XPDPM<br>10-20</th>
					<th
						id="xppmd30"
						@click="sortKeyChange('xppmd30')"
						:class="[(this.sortKey === 'xppmd30') ? ['sorted', this.sortDir, 'primary'] : '']"
					>XPDPM<br>20-30</th>
					<th
						id="xppmdEnd"
						@click="sortKeyChange('xppmdEnd')"
						:class="[(this.sortKey === 'xppmdEnd') ? ['sorted', this.sortDir, 'primary'] : '']"
					>XPDPM<br>30-End</th>
				</tr>
			</thead>
			<tbody>
				<tr
					v-for="(champion, index) in sortedChamps"
					:key="index"
					:class="evenOdd(index)"
				>
					<td :class="{'primary':(sortKey === 'championName')}">
						<span class="champTableName">
							<img :src="`/images/championThumbs_50x50/${champion.championImgName}.png`" alt="" />
							<span class="level">{{champion.championName}}</span>
						</span>
					</td>
					<td :class="{'primary':(sortKey === 'picks')}">{{champion.picks}}</td>
					<td :class="{'primary':(sortKey === 'winRate')}">{{champion.winRate}}%</td>
					<td :class="{'primary':(sortKey === 'xppm10')}">{{champion.xppm10}}</td>
					<td :class="{'primary':(sortKey === 'xppm20')}">{{champion.xppm20}}</td>
					<td :class="{'primary':(sortKey === 'xppm30')}">{{champion.xppm30}}</td>
					<td :class="{'primary':(sortKey === 'xppmEnd')}">{{champion.xppmEnd}}</td>
					<td :class="{'primary':(sortKey === 'xppmd10')}">{{champion.xppmd10}}</td>
					<td :class="{'primary':(sortKey === 'xppmd20')}">{{champion.xppmd20}}</td>
					<td :class="{'primary':(sortKey === 'xppmd30')}">{{champion.xppmd30}}</td>
					<td :class="{'primary':(sortKey === 'xppmdEnd')}">{{champion.xppmdEnd}}</td>
				</tr>
			</tbody>
		</table>

		<table class="tablesorter champStatsMainTable killTypeTable" id="killTypeTable" v-if="this.selectedTable === 'killType'">
			<thead>
				<tr>
					<th
						id="championName"
						@click="sortKeyChange('championName')"
						:class="[
							(this.sortKey === 'championName') ? ['sorted', 'primary'] : '',
							(this.sortKey === 'championName' && this.sortDir === 'asc') ? 'desc' : ''
						]"
					>Name</th>
					<th
						id="picks"
						@click="sortKeyChange('picks')"
						:class="[(this.sortKey === 'picks') ? ['sorted', this.sortDir, 'primary'] : '']"
					>Games</th>
					<th
						id="winRate"
						@click="sortKeyChange('winRate')"
						:class="[(this.sortKey === 'winRate') ? ['sorted', this.sortDir, 'primary'] : '']"
					>Win %</th>
					<th
						id="doubleKills"
						@click="sortKeyChange('doubleKills')"
						:class="[(this.sortKey === 'doubleKills') ? ['sorted', this.sortDir, 'primary'] : '']"
					>DKs</th>
					<th
						id="gpDoubleKill"
						@click="sortKeyChange('gpDoubleKill')"
						:class="[(this.sortKey === 'gpDoubleKill') ? ['sorted', this.sortDir, 'primary'] : '']"
					>GPDK</th>
					<th
						id="tripleKills"
						@click="sortKeyChange('tripleKills')"
						:class="[(this.sortKey === 'tripleKills') ? ['sorted', this.sortDir, 'primary'] : '']"
					>TKs</th>
					<th
						id="gpTripleKill"
						@click="sortKeyChange('gpTripleKill')"
						:class="[(this.sortKey === 'gpTripleKill') ? ['sorted', this.sortDir, 'primary'] : '']"
					>GPTK</th>
					<th
						id="quadraKills"
						@click="sortKeyChange('quadraKills')"
						:class="[(this.sortKey === 'quadraKills') ? ['sorted', this.sortDir, 'primary'] : '']"
					>QKs</th>
					<th
						id="gpQuadraKill"
						@click="sortKeyChange('gpQuadraKill')"
						:class="[(this.sortKey === 'gpQuadraKill') ? ['sorted', this.sortDir, 'primary'] : '']"
					>GPQK</th>
					<th
						id="pentaKills"
						@click="sortKeyChange('pentaKills')"
						:class="[(this.sortKey === 'pentaKills') ? ['sorted', this.sortDir, 'primary'] : '']"
					>PKs</th>
					<th
						id="gpPentaKill"
						@click="sortKeyChange('gpPentaKill')"
						:class="[(this.sortKey === 'gpPentaKill') ? ['sorted', this.sortDir, 'primary'] : '']"
					>GPPK</th>
					<th
						id="fbka"
						@click="sortKeyChange('fbka')"
						:class="[(this.sortKey === 'fbka') ? ['sorted', this.sortDir, 'primary'] : '']"
					>FBKAs</th>
					<th
						id="gpFBKA"
						@click="sortKeyChange('gpFBKA')"
						:class="[(this.sortKey === 'gpFBKA') ? ['sorted', this.sortDir, 'primary'] : '']"
					>GPFBKA</th>
					<th
						id="fbDeaths"
						@click="sortKeyChange('fbDeaths')"
						:class="[(this.sortKey === 'fbDeaths') ? ['sorted', this.sortDir, 'primary'] : '']"
					>FBDs</th>
					<th
						id="gpFBD"
						@click="sortKeyChange('gpFBD')"
						:class="[(this.sortKey === 'gpFBD') ? ['sorted', this.sortDir, 'primary'] : '']"
					>GPFBD</th>
				</tr>
			</thead>
			<tbody>
				<tr
					v-for="(champion, index) in sortedChamps"
					:key="index"
					:class="evenOdd(index)"
				>
					<td :class="{'primary':(sortKey === 'championName')}">
						<span class="champTableName">
							<img :src="`/images/championThumbs_50x50/${champion.championImgName}.png`" alt="" />
							<span class="level">{{champion.championName}}</span>
						</span>
					</td>
					<td :class="{'primary':(sortKey === 'picks')}">{{champion.picks}}</td>
					<td :class="{'primary':(sortKey === 'winRate')}">{{champion.winRate}}%</td>
					<td :class="{'primary':(sortKey === 'doubleKills')}">{{champion.doubleKills}}</td>
					<td :class="{'primary':(sortKey === 'gpDoubleKill')}">{{champion.gpDoubleKill}}</td>
					<td :class="{'primary':(sortKey === 'tripleKills')}">{{champion.tripleKills}}</td>
					<td :class="{'primary':(sortKey === 'gpTripleKill')}">{{champion.gpTripleKill}}</td>
					<td :class="{'primary':(sortKey === 'quadraKills')}">{{champion.quadraKills}}</td>
					<td :class="{'primary':(sortKey === 'gpQuadraKill')}">{{champion.gpQuadraKill}}</td>
					<td :class="{'primary':(sortKey === 'pentaKills')}">{{champion.pentaKills}}</td>
					<td :class="{'primary':(sortKey === 'gpPentaKill')}">{{champion.gpPentaKill}}</td>
					<td :class="{'primary':(sortKey === 'fbka')}">{{champion.fbka}}</td>
					<td :class="{'primary':(sortKey === 'gpFBKA')}">{{champion.gpFBKA}}</td>
					<td :class="{'primary':(sortKey === 'fbDeaths')}">{{champion.fbDeaths}}</td>
					<td :class="{'primary':(sortKey === 'gpFBD')}">{{champion.gpFBD}}</td>
				</tr>
			</tbody>
		</table>

		<table class="tablesorter champStatsMainTable objTable" v-if="this.selectedTable === 'obj'">
			<thead>
				<tr>
					<th
						id="championName"
						@click="sortKeyChange('championName')"
						:class="[
							(this.sortKey === 'championName') ? ['sorted', 'primary'] : '',
							(this.sortKey === 'championName' && this.sortDir === 'asc') ? 'desc' : ''
						]"
					>Name</th>
					<th
						id="picks"
						@click="sortKeyChange('picks')"
						:class="[(this.sortKey === 'picks') ? ['sorted', this.sortDir, 'primary'] : '']"
					>Games</th>
					<th
						id="winRate"
						@click="sortKeyChange('winRate')"
						:class="[(this.sortKey === 'winRate') ? ['sorted', this.sortDir, 'primary'] : '']"
					>Win %</th>
					<th
						id="objControl"
						@click="sortKeyChange('objControl')"
						:class="[(this.sortKey === 'objControl') ? ['sorted', this.sortDir, 'primary'] : '']"
					>Obj Ctrl</th>
					<th
						id="heraldControl"
						@click="sortKeyChange('heraldControl')"
						:class="[(this.sortKey === 'heraldControl') ? ['sorted', this.sortDir, 'primary'] : '']"
					>H Ctrl</th>
					<th
						id="dragonControl"
						@click="sortKeyChange('dragonControl')"
						:class="[(this.sortKey === 'dragonControl') ? ['sorted', this.sortDir, 'primary'] : '']"
					>D Ctrl</th>
					<th
						id="baronControl"
						@click="sortKeyChange('baronControl')"
						:class="[(this.sortKey === 'baronControl') ? ['sorted', this.sortDir, 'primary'] : '']"
					>B Ctrl</th>
					<th
						id="towerControl"
						@click="sortKeyChange('towerControl')"
						:class="[(this.sortKey === 'towerControl') ? ['sorted', this.sortDir, 'primary'] : '']"
					>T Ctrl</th>
					<th
						id="inhibControl"
						@click="sortKeyChange('inhibControl')"
						:class="[(this.sortKey === 'inhibControl') ? ['sorted', this.sortDir, 'primary'] : '']"
					>I Ctrl</th>
					<th
						id="heraldsPG"
						@click="sortKeyChange('heraldsPG')"
						:class="[(this.sortKey === 'heraldsPG') ? ['sorted', this.sortDir, 'primary'] : '']"
					>HPG</th>
					<th
						id="dragonsPG"
						@click="sortKeyChange('dragonsPG')"
						:class="[(this.sortKey === 'dragonsPG') ? ['sorted', this.sortDir, 'primary'] : '']"
					>DPG</th>
					<th
						id="baronsPG"
						@click="sortKeyChange('baronsPG')"
						:class="[(this.sortKey === 'baronsPG') ? ['sorted', this.sortDir, 'primary'] : '']"
					>BPG</th>
					<th
						id="firstHeraldRate"
						@click="sortKeyChange('firstHeraldRate')"
						:class="[(this.sortKey === 'firstHeraldRate') ? ['sorted', this.sortDir, 'primary'] : '']"
					>FH Rate</th>
					<th
						id="firstDragonRate"
						@click="sortKeyChange('firstDragonRate')"
						:class="[(this.sortKey === 'firstDragonRate') ? ['sorted', this.sortDir, 'primary'] : '']"
					>FD Rate</th>
					<th
						id="firstBaronRate"
						@click="sortKeyChange('firstBaronRate')"
						:class="[(this.sortKey === 'firstBaronRate') ? ['sorted', this.sortDir, 'primary'] : '']"
					>FB Rate</th>
					<th
						id="firstTowerRate"
						@click="sortKeyChange('firstTowerRate')"
						:class="[(this.sortKey === 'firstTowerRate') ? ['sorted', this.sortDir, 'primary'] : '']"
					>FT Rate</th>
					<th
						id="firstInhibRate"
						@click="sortKeyChange('firstInhibRate')"
						:class="[(this.sortKey === 'firstInhibRate') ? ['sorted', this.sortDir, 'primary'] : '']"
					>FI Rate</th>
				</tr>
			</thead>
			<tbody>
				<tr
					v-for="(champion, index) in sortedChamps"
					:key="index"
					:class="evenOdd(index)"
				>
					<td :class="{'primary':(sortKey === 'championName')}">
						<span class="champTableName">
							<img :src="`/images/championThumbs_50x50/${champion.championImgName}.png`" alt="" />
							<span class="level">{{champion.championName}}</span>
						</span>
					</td>
					<td :class="{'primary':(sortKey === 'picks')}">{{champion.picks}}</td>
					<td :class="{'primary':(sortKey === 'winRate')}">{{champion.winRate}}%</td>
					<td :class="{'primary':(sortKey === 'objControl')}">{{champion.objControl}}%</td>
					<td :class="{'primary':(sortKey === 'heraldControl')}">{{champion.heraldControl}}%</td>
					<td :class="{'primary':(sortKey === 'dragonControl')}">{{champion.dragonControl}}%</td>
					<td :class="{'primary':(sortKey === 'baronControl')}">{{champion.baronControl}}%</td>
					<td :class="{'primary':(sortKey === 'towerControl')}">{{champion.towerControl}}%</td>
					<td :class="{'primary':(sortKey === 'inhibControl')}">{{champion.inhibControl}}%</td>
					<td :class="{'primary':(sortKey === 'heraldsPG')}">{{champion.heraldsPG}}</td>
					<td :class="{'primary':(sortKey === 'dragonsPG')}">{{champion.dragonsPG}}</td>
					<td :class="{'primary':(sortKey === 'baronsPG')}">{{champion.baronsPG}}</td>
					<td :class="{'primary':(sortKey === 'firstHeraldRate')}">{{champion.firstHeraldRate}}%</td>
					<td :class="{'primary':(sortKey === 'firstDragonRate')}">{{champion.firstDragonRate}}%</td>
					<td :class="{'primary':(sortKey === 'firstBaronRate')}">{{champion.firstBaronRate}}%</td>
					<td :class="{'primary':(sortKey === 'firstTowerRate')}">{{champion.firstTowerRate}}%</td>
					<td :class="{'primary':(sortKey === 'firstInhibRate')}">{{champion.firstInhibRate}}%</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import _ from 'lodash';

export default {
	name: 'champ-stats-table',
	data () {
		return {
			selectedTable: 'general'
		};
	},
	computed: {
		...mapState([
			'championVersion',
			'sortKey',
			'sortDir'
		]),
		...mapGetters(['getChampionList']),
		sortedChamps () {
			if ((this.sortKey === 'gpDoubleKill') || (this.sortKey === 'gpTripleKill') || (this.sortKey === 'gpQuadraKill') || (this.sortKey === 'gpPentaKill') || (this.sortKey === 'gpFBKA') || (this.sortKey === 'gpFBD')) {
				const myKey = this.sortKey;
				let dupChamps = this.getChampionList;
				dupChamps = _.forEach(dupChamps, (dupChamp) => {
					if (dupChamp[myKey] === '-') {
						if (this.sortKey === 'gpFBD') {
							dupChamp[myKey] = -99999999999999;
						} else {
							dupChamp[myKey] = 99999999999999;
						}
					}
				});
				dupChamps = _.orderBy(dupChamps, [myKey, 'championName'], [this.sortDir, 'asc']);
				dupChamps = _.forEach(dupChamps, (dupChamp) => {
					if ((dupChamp[myKey] === 99999999999999) || (dupChamp[myKey] === -99999999999999)) {
						dupChamp[myKey] = '-';
					}
				});
				return dupChamps;
			} else if ((this.sortKey === 'cspm10') || (this.sortKey === 'cspm20') || (this.sortKey === 'cspm30') || (this.sortKey === 'cspmEnd') || (this.sortKey === 'cspmd10') || (this.sortKey === 'cspmd20') || (this.sortKey === 'cspmd30') || (this.sortKey === 'cspmdEnd') || (this.sortKey === 'xppm10') || (this.sortKey === 'xppm20') || (this.sortKey === 'xppm30') || (this.sortKey === 'xppmEnd') || (this.sortKey === 'xppmd10') || (this.sortKey === 'xppmd20') || (this.sortKey === 'xppmd30') || (this.sortKey === 'xppmdEnd') || (this.sortKey === 'gpm10') || (this.sortKey === 'gpm20') || (this.sortKey === 'gpm30') || (this.sortKey === 'gpmEnd') || (this.sortKey === 'gpmd10') || (this.sortKey === 'gpmd20') || (this.sortKey === 'gpmd30') || (this.sortKey === 'gpmdEnd')) {
				let dupChamps = this.getChampionList;

				dupChamps = _.forEach(dupChamps, (dupChamp) => {
					if (dupChamp[this.sortKey] === '-') {
						if (this.sortDir === 'desc') {
							dupChamp[this.sortKey] = -99999999999999;
						} else {
							dupChamp[this.sortKey] = 99999999999999;
						}
					}
				});

				let newChampArray;
				let positiveChamps = _.filter(dupChamps, (o) => (o[this.sortKey] >= 0));
				positiveChamps = _.orderBy(positiveChamps, [this.sortKey, 'championName'], [this.sortDir, 'asc']);
				positiveChamps = positiveChamps.sort((a, b) => b[this.sortKey] - a[this.sortKey]);
				let negativeChamps = _.filter(dupChamps, (o) => (o[this.sortKey] < 0));
				negativeChamps = _.reverse(_.orderBy(negativeChamps, [this.sortKey, 'championName'], [this.sortDir, 'asc']));
				negativeChamps = negativeChamps.sort((a, b) => b[this.sortKey] - a[this.sortKey]);
				if (this.sortDir === 'desc') {
					newChampArray = _.concat(positiveChamps, negativeChamps);
				} else {
					newChampArray = _.concat(_.reverse(negativeChamps), _.reverse(positiveChamps));
				}

				newChampArray = _.forEach(newChampArray, (dupChamp) => {
					if ((dupChamp[this.sortKey] === 99999999999999) || (dupChamp[this.sortKey] === -99999999999999)) {
						dupChamp[this.sortKey] = '-';
					}
				});

				return newChampArray;
			}	else {
				return _.orderBy(this.getChampionList, [this.sortKey, 'championName'], [this.sortDir, 'asc']);
			}
		}
	},
	methods: {
		sortKeyChange (key) {
			this.$store.dispatch('sortTable', {
				sortKey: key
			});
		},
		evenOdd (i) {
			if (((i + 1) % 2) === 0) {
				return 'even';
			} else {
				return 'odd';
			}
		}
	}
};
</script>

<style lang="scss">
.champ-stats-table-div {
	height: 100%;
	width: 100%;

	p {
		margin: 10px 100px;
	}
}

.champStatsTableWrapper {
	display: block;
	height: 100%;
}

table.tablesorter thead tr {
	display: grid;
	margin-bottom: 5px;
}

table.tablesorter {
	width: 100%;

	th:first-child {
		border-radius: 4px 0 0 4px;
	}

	th:last-child {
		border-radius: 0 4px 4px 0;
	}
}

table.tablesorter tbody {
	overflow-x: hidden;
	overflow-y: auto;
	width: 100vw;

	tr {
		display: grid;

		td {
			line-height: 36px;
		}
	}
}

table.champStatsMainTable {
	top: 250px;
}

table.tablesorter.generalTable thead tr,
table.tablesorter.generalTable tbody tr {
	grid-template-columns: repeat(13, 1fr);
}

table.tablesorter.dmgHealTable thead tr,
table.tablesorter.dmgHealTable tbody tr {
	grid-template-columns: repeat(15, 1fr);
}

table.tablesorter.csTable thead tr,
table.tablesorter.csTable tbody tr {
	grid-template-columns: repeat(13, 1fr);
}

table.tablesorter.goldTable thead tr,
table.tablesorter.goldTable tbody tr {
	grid-template-columns: repeat(13, 1fr);
}

table.tablesorter.xpTable thead tr,
table.tablesorter.xpTable tbody tr {
	grid-template-columns: repeat(11, 1fr);
}

table.tablesorter.killTypeTable thead tr,
table.tablesorter.killTypeTable tbody tr {
	grid-template-columns: repeat(15, 1fr);
}

table.tablesorter.objTable thead tr,
table.tablesorter.objTable tbody tr {
	grid-template-columns: repeat(17, 1fr);
}

/*
	TABLESORTER
*/
table.tablesorter {
	margin: 0px auto 0px auto;
	font-size: 14px;
	width: 98%;
	text-align: center;
	border-collapse: collapse;

	thead {
		position: sticky;
		top: 175px;
		z-index: 3;
	}

	thead tr {
		position: sticky;
		top: 175px;
		z-index: 3;
	}
}

table.tablesorter thead tr th,
table.tablesorter tfoot tr th {
	background-color: var(--foreground);
	color: var(--white);
	font-size: 14px;
	padding: 4px 0;
	text-align: center;
}

table.tablesorter thead tr {
	cursor: pointer;
}

table.tablesorter tbody td {
	color: rgb(40, 47, 56);
	cursor: cell;
}

table.tablesorter tbody tr.odd {
	background: var(--dark-2);

	td {
		color: lighten(rgb(150, 208, 255), 0%);
		color: var(--white);
	}
}

table.tablesorter tbody tr.even {
	background: darken(#202441, 5%);

	td {
		color: var(--white);
	}
}

table.tablesorter tbody tr:hover {
	background-color: var(--secondary-blue);

	td {
		color: var(--white);
	}
}

table.tablesorter thead th:hover,
table.tablesorter tbody td:hover {
	background: lighten(rgb(5, 21, 41), 15%);
	color: var(--white);
}

table.tablesorter thead th.sorted {
	background: lighten(#2D3259, 25%);
	color: var(--white);
}

table.tablesorter thead th.sorted::after {
	content: '';
	display: inline-block;
	margin-left: 0px;
	margin-top: -2px;
	border-left: 6px solid transparent;
	border-right: 6px solid transparent;
}

table.tablesorter thead th.sorted.desc::after {
	border-top: 8px solid lighten(rgb(255, 219, 107), 15%);
}

table.tablesorter thead th.sorted.asc::after {
	border-bottom: 8px solid lighten(rgb(255, 219, 107), 15%);
}

table.tablesorter tr.odd td.primary {
	background: darken(rgb(13, 53, 99), 15%);
	color: lighten(rgb(150, 208, 255), 15%);
}

table.tablesorter tr.even td.primary {
	background: darken(rgb(13, 53, 99), 25%);
	color: lighten(rgb(150, 208, 255), 15%);
}

table.tablesorter tr.even:hover td.primary,
table.tablesorter tr.odd:hover td.primary {
	background: #4c5361;
	color: #ffc966;
}

table.champStatsMainTable tbody tr td img {
	height: 30px;
	width: 30px;
	vertical-align: middle;
	padding: 3px 5px;
}

table.champStatsMainTable tbody tr td:nth-child(1) {
	white-space: nowrap;

	span.champTableName {
		float: left;
	}
}

table.champStatsMainTable thead tr th:nth-child(1) {
	text-align: center;
}

table.champStatsMainTable tbody tr td:nth-child(1) {
	text-align: center;
}

table.champStatsMainTable thead tr th:nth-child(2),
table.champStatsMainTable tbody tr td:nth-child(2) {
	text-align: center;
	padding-left: 5px;
}

table.champStatsMainTable thead tr th:nth-last-child(1),
table.champStatsMainTable tbody tr td:nth-last-child(1) {
	padding-right: 5px;
}

.heightTester {
	position: fixed;
	top: 0;
	height: 222px;
	width: 100%;
	background: var(--dark-2);
	z-index: 2;
}

.buttonGroup {
	width: 98%;
	margin: 0px auto 35px auto;
	text-align: center;
	display: grid;
	grid-template-columns: repeat(7, 1fr);
	position: sticky;
	top: 135px;
	z-index: 4;
}

.tableSelectButton {
	display: inline-block;
	text-align: center;
	padding: 0;
	height: 30px;
	color: var(--grey-2);
	cursor: pointer;
	text-transform: uppercase;
	font-weight: bold;
	font-size: 18px;
	line-height: 30px;
	border: 0;
	transform: translateZ(0);
	backface-visibility: hidden;
	overflow:hidden;

	&:before {
		content: "";
		position: absolute;
		z-index: -1;
		left: 50%;
		right: 50%;
		bottom: 0;
		background: var(--secondary-blue);
		height: 2px;
		transition-property: left right;
		transition-duration: 0.3s;
		transition-timing-function: ease-out;
	}

	&:hover:before {
		left: 0;
		right: 0;
	}

	&.active {
		color: var(--white);
	}

	&.active:before {
		content: "";
		position: absolute;
		z-index: -1;
		left: 0%;
		right: 0%;
		height: 2px;
		background: var(--secondary-blue);
	}
}

.tableSelectButton:hover {
	color: var(--white);
}

p.summaryViewLink {
	z-index: 5;
	position: fixed;
	top: 85px;
	left: 25px;
	float: left;
	margin: 0;
	background: var(--secondary-blue);
	color: var(--white);
	border-radius: 4px;
	padding: 5px;
}
</style>
