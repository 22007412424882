<template>
	<div class="app">
		<NavSite />
		<router-view
			:key="$route.fullPath"
			id="site-content-wrapper"
			class="page"
		/>
		<FooterSite />
	</div>
</template>

<script>
import NavSite from './components/_global/NavSite.vue';
import FooterSite from './components/_global/FooterSite.vue';

export default {
	name: 'app',
	components: {
		NavSite,
		FooterSite
	}
};
</script>

<style lang="scss" scoped>
.page {
	min-height: calc(100vh - 51px - 196px);
}
</style>
