<template>
	<div class="testingHeight">
		<div
			v-show="(this.view !== 'table')"
			id="champion-index-div"
		>
			<div id="roleStatSummary">
				<page-champ-state
					percent="yes"
					title="Pick Rate"
					rate="pickRate"
					v-bind:data="getPickRateSummary"
					:handleChampionClick="handleChampionClick"
					:testRoute="testRoute"
				></page-champ-state>
				<page-champ-state
					percent="yes"
					title="Ban Rate"
					rate="banRate"
					v-bind:data="getBanRateSummary"
					:handleChampionClick="handleChampionClick"
					:testRoute="testRoute"
				></page-champ-state>
				<page-champ-state
					percent="yes"
					title="Win Rate"
					rate="winRate"
					v-bind:data="getWinRateSummary"
					:handleChampionClick="handleChampionClick"
					:testRoute="testRoute"
				></page-champ-state>
				<page-champ-state
					percent="no"
					title="KDA"
					rate="kda"
					v-bind:data="getKDASummary"
					:handleChampionClick="handleChampionClick"
					:testRoute="testRoute"
				></page-champ-state>
			</div>
			<div id="championsDisplay">
				<div
					@click="handleChampionClick($event)"
					class="championDisplay"
					v-for="(champion, index) in getChampionList"
					:key="index"
					:data-champion="champion.championName"
				>
					<img
						class="championDisplayImage"
						:src="`/images/championThumbs_50x50/${champion.championImgName}.png`"
					/>
					<h4>{{ champion.championName }}</h4>
				</div>
			</div>
		</div>

		<champ-stats-table v-show="(this.view === 'table')"></champ-stats-table>
	</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import ChampStatsTable from './champStatsTable.vue';
import pageChampState from './pageChampState.vue';

export default {
	name: 'page-role-summary',
	components: {
		ChampStatsTable,
		pageChampState
	},
	computed: {
		...mapState(['champDataGrab', 'championVersion', 'view']),
		...mapGetters([
			'getChampionList',
			'getPickRateSummary',
			'getBanRateSummary',
			'getWinRateSummary',
			'getKDASummary'
		])
	},
	methods: {
		testRoute (sortKey, sortDir) {
			this.$store.dispatch('setView', { sortKey, sortDir });
		},
		handleChampionClick (e) {
			const champName = e.currentTarget.dataset.champion;
			this.$router.push({
				name: 'champion',
				params: { champion: champName, role: 'all', opponent: 'all' }
			});
		}
	}
};
</script>

<style lang="scss" scoped>
	.championDisplayImage {
		cursor: pointer;
	}
</style>
